import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import { getField, updateField } from 'vuex-map-fields';
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
  state: {
    controlValue: {
        showLayout: true,
        floatLayout: true,
        enableDownload: true,
        previewModal: true,
        paginateElementsByHeight: 11000,
        manualPagination: false,
        filename: 'Invoice',
        pdfQuality: 4,
        pdfFormat: 'a4',
        pdfOrientation: 'portrait',
        pdfContentWidth: '800px',
        minHeight:12,
        showZero: false,
        // outstanding
        loan_start_date:null,
        loan_end_date:null,
        loan_type_id:null,
        // Overdue , Repayment
        pay_date_from:null,
        pay_date_to:null,
    }
},
mutations: {
    updateField
},

getters: {
    getField
},
})
