import Vue from 'vue'
import router from '@/router'
import Swal from 'sweetalert2'
// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_SERVICE_URL,
  // timeout: 1000,
  headers: { 'Authorization': 'Bearer ' + localStorage.getItem('accessToken') }
})
let self = this;
// Add a response interceptor
axiosIns.interceptors.response.use((response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response dat
  return response;
}, (error) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error

  if (error.response.status == 401) {
    localStorage.clear();

    // localStorage.setItem("isWarningShow", 1);
    //window.location.replace('/login')
    console.log('401 Unauthorized')

    router.replace('/login').then(() => {
      Swal.fire({
        text: "你已被登出！",
        // text: "你已被登出！有其他用戶使用這個帳戶",
        confirmButtonText: "確定",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        showClass: {
          popup: "animate__animated animate__bounceIn",
        },
        buttonsStyling: false,
      });
    });
  }


  if(error.response.status == 429){
    localStorage.clear();

    // localStorage.setItem("isWarningShow", 1);

    //window.location.replace('/login')
    console.log('429 Unauthorized')

    router.replace('/login').then(() => {

      Swal.fire({
        text: "你已被登出！檢測用戶正在被攻擊，系統限制請求一小時！",
        confirmButtonText: "確定",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        showClass: {
          popup: "animate__animated animate__bounceIn",
        },
        buttonsStyling: false,
      });
    });
  }


  return Promise.reject(error);
});

Vue.prototype.$http = axiosIns

export default axiosIns
