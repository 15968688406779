export function getCorrectDateTime(datetime, withoutTime)
{
    let utcDate
    if(!datetime) return 
    
    if(datetime.indexOf('Z') > -1){
        utcDate = new Date(datetime.slice(0,19));
    }else{
        utcDate = new Date(datetime);
    }

    // Get the timezone offset in minutes
    const timezoneOffset = new Date().getTimezoneOffset();

    // Convert the offset to milliseconds
    const offsetInMs = timezoneOffset * 60 * 1000;

    // Add the offset to the UTC time
    const localTime = new Date(utcDate.getTime() - offsetInMs);

    // Format the local time as a string in the YYYY-MM-DD HH:MM:SS format
    const year = localTime.getFullYear();
    const month = String(localTime.getMonth() + 1).padStart(2, '0');
    const day = String(localTime.getDate()).padStart(2, '0');
    const hours = String(localTime.getHours()).padStart(2, '0');
    const minutes = String(localTime.getMinutes()).padStart(2, '0');
    const seconds = String(localTime.getSeconds()).padStart(2, '0');
    let localTimeString
    if(withoutTime){
        localTimeString = `${year}-${month}-${day}`;
    }else{
        localTimeString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    return localTimeString;
}

export const getDateTime = (time,withoutTime) => {
    const eventTime = time ? new Date(time) : new Date();
    const y = eventTime.getFullYear();
    const M =
        Number(eventTime.getMonth() + 1) >= 10
            ? Number(eventTime.getMonth() + 1)
            : "0" + Number(eventTime.getMonth() + 1);
    const d =
        Number(eventTime.getDate()) >= 10
            ? Number(eventTime.getDate())
            : "0" + Number(eventTime.getDate());
    const h =
        eventTime.getHours() >= 10
            ? eventTime.getHours()
            : "0" + eventTime.getHours();
    const m =
        eventTime.getMinutes() >= 10
            ? eventTime.getMinutes()
            : "0" + eventTime.getMinutes();
    if (withoutTime) {
        return `${y}-${M}-${d}`;
    } else {
        return `${y}-${M}-${d} ${h}:${m}`;
    }
};


// export const maxData = 'today'
// const now = new Date()
// export const maxDate = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`
