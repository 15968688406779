export default [
    {
        path: "/loans",
        name: "loans-list",
        component: () => import("@/views/loan/loans-list/LoansList.vue"),
        meta:{
            action: "read",
            resource: "Auth",
        }
    },
]