export default [
    {
        path: "/clients",
        name: "clients-list",
        component: () => import("@/views/client/clients-list/ClientsList.vue"),
        meta:{
            action: "read",
            resource: "Auth",
        }
    },
    {
        path: "/clients/edit/:id",
        name: "clients-edit",
        component: () => import("@/views/client/clients-edit/ClientsEdit.vue"),
        meta:{
            action:"read",
            resource:"Auth"
        }
    },


]