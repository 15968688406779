export default [
  // {
  //   path: '/coins',
  //   name: 'coin',
  //   component: () => import('@/views/coins/coin.vue-bak'),

  //   meta: {
  //     action: 'read',
  //     resource: 'Auth',
  //   },
  // },
  {
    path: '/records-list',
    name: 'records-list',
    component: () => import('@/views/coins/records-list/recordList.vue'),

    meta: {
      action: 'read',
      resource: 'Auth',
    },
    
  },
];
