export default [
    {
      path: "/report/outstanding-balance",
      name: "report-outstanding-balance",
      component: () => import("@/views/report/outstanding/reportPreview.vue"),
      meta: {
        resource: "Auth",
        action: "read",
      },
    },
    {
      path: "/report/repayment-details",
      name: "report-repayment-details",
      component: () => import("@/views/report/repayment/reportPreview.vue"),
      meta: {
        resource: "Auth",
        action: "read",
      },
    },
    {
      path: "/report/overdue-statement",
      name: "report-overdue-statement",
      component: () => import("@/views/report/overdue/reportPreview.vue"),
      meta: {
        resource: "Auth",
        action: "read",
      },
    },
    {
      path: "/report/loan-cleared-statement",
      name: "report-loan-cleared-statement",
      component: () => import("@/views/report/loan-cleared/reportPreview.vue"),
      meta: {
        resource: "Auth",
        action: "read",
      },
    },
    {
      path: "/report/loan-detail-report",
      name: "report-loan-detail-report",
      component: () => import("@/views/report/loan-detail/reportPreview.vue"),
      meta: {
        resource: "Auth",
        action: "read",
      },
    },
    {
      path: "/report/payment-detail-report",
      name: "report-payment-detail-report",
      component: () => import("@/views/report/payment-detail/reportPreview.vue"),
      meta: {
        resource: "Auth",
        action: "read",
      },
    },
]