import { $themeBreakpoints } from '@themeConfig'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  maxDate:null,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    user_info:null,
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    user_contact_name: state =>{
      const { user_info } = state
      return user_info && user_info.userData.contact_name
    },
    user_company_name_cn: state =>{
      const { user_info } = state
      return user_info && user_info.userData.company_name_cn
    },
    user_balance: state =>{
      const { user_info } = state
      return user_info && user_info.balance
    },
    user_supplier_id: state =>{
      const { user_info } = state
      return user_info && user_info.userData.supplier_id
    },
    user_parent: state =>{
      const { user_info } = state
      return user_info && user_info.userData.parent_id
    },
    user_id: state => {
      const {user_info} = state
      return user_info && user_info.userData.id
    },
    signature_url: state => {
      const {user_info} = state
      return user_info && user_info.userData.signature_url
    }
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
  },
  actions: {
    fetchMaxDate({state}) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`/time`)
        .then(response => {
          state.maxDate = `${response.data.today} ${response.data.now}`;
        })
        .catch(error => reject(error));
      })
    },
    fetchOptionList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`/options`, {params:queryParams})
        .then(response => resolve(response))
        .catch(error => reject(error));
      })
    },
    fetchCoinsBalance({state}) {
      axiosIns
        .get("/coin/balance")
        .then((response) => {
          state.user_info = response.data;
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching balance",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    fetchOptionList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`/options`, {params:queryParams})
        .then(response => resolve(response))
        .catch(error => reject(error));
      })
    },
    fetchLoanSeachDownload(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/record/search',  {params: queryParams })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      })
    },
    fetchDashboardSearchRecord(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/record/list', {params:queryParams})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      })
    },
    fetchNewestDashboardSearchRecord(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/record/', {params:queryParams})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      })
    },
    fetchLoanSearchForm(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.post('/client_loan', data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      })
    },
    addRejectRecord(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.post('/client_reject_option', data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      })
    },
    editRejectRecord(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.put('/client_reject_option', data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      })
    },
    getEditRejectRecord(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/client_reject_option/logs', {params:queryParams})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      })
    },
    deleteRejectRecord(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.delete('/client_reject_option',{ params: queryParams })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      })
    },
    addAccountRecord(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.post('/client_account_option', data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      })
    },
    editAccountRecord(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.put('/client_account_option', data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      })
    },
    getEditAccountRecord(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/client_account_option/logs', {params:queryParams})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      })
    },
    deleteAccountRecord(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.delete('/client_account_option', { params: queryParams })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      })
    },
    checkNumberOfRecord(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/record/checkNumberOfRecord', {params:queryParams})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      })
    },
    extraRecord(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.post('/record/extraRecord', data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      })
    },
    fetchSuppliersInfo(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/info", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    uploadSignature(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/signature", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteSignature(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/signature")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
}
