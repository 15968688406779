export default [
    {
        path: "/suppliers/edit",
        name: "suppliers-edit",
        component: () => import("@/views/supplier/suppliers-edit/SuppliersEdit.vue"),
        meta:{
            action:"read",
            resource:"Auth"
        }
    },
    {
        path: "/suppliers/info",
        name: "suppliers-info",
        component: () => import("@/views/supplier/suppliers-info/suppliersInfo.vue"),
        meta:{
            action:"read",
            resource:"Auth"
        }
    },
    {
        path: "/supplier/detail",
        name: "suppliers-detail",
        component: () => import("@/views/supplier/suppliers-detail/supplierDetail.vue"),
        meta:{
            action:"read",
            resource:"Auth"
        }
    },
]