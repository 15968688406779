export default [
    {
        path: "/newContracts",
        name: "newContracts-list",
        component: () => import("@/views/newContract/newContracts-list/NewContractsList.vue"),
        meta:{
            action: "read",
            resource: "Auth",
        }
    },
]