export default [
    {
        path: "/payment/paypal/exchange",
        name: "paypal",
        component: () => import("@/views/payment/paypal/exchange.vue"),
        meta:{
            action:"read",
            resource:"Auth"
        }
    },
    {
        path: "/payment/receive-account/list",
        name: "receive-account-list",
        component: () => import("@/views/payment/receive-account/receive-accounts-list/receiveAccountsList.vue"),
        meta:{
            action:"read",
            resource:"Auth"
        }
    },
    {
        path: "/payment/receive-account/create",
        name: "receive-account-create",
        component: () => import("@/views/payment/receive-account/receive-account-edit/receiveAccountEdit.vue"),
        meta:{
            action:"read",
            resource:"Auth"
        }
    },
    {
        path: "/payment/receive-account/edit/:id",
        name: "receive-account-edit",
        component: () => import("@/views/payment/receive-account/receive-account-edit/receiveAccountEdit.vue"),
        meta:{
            action:"read",
            resource:"Auth"
        }
    },
    {
        path: "/payment/repayment/list",
        name: "repayment-list",
        component: () => import("@/views/payment/repayment/repayments-list/repaymentsList.vue"),
        meta:{
            action:"read",
            resource:"Auth"
        }
    },
    {
        path: "/payment/repayment/create",
        name: "repayment-create",
        component: () => import("@/views/payment/repayment/repayment-edit/repaymentEdit.vue"),
        meta:{
            action:"read",
            resource:"Auth"
        }
    },
    // {
    //     path: "/payment/repayment/view/:id",
    //     name: "repayment-view",
    //     component: () => import("@/views/payment/repayment/repayment-view/repaymentView.vue"),
    //     meta:{
    //         action:"read",
    //         resource:"Auth"
    //     }
    // },
    {
        path: "/payment/repayment/edit/:id/:contract_id",
        name: "repayment-edit",
        component: () => import("@/views/payment/repayment/repayment-edit/repaymentEdit.vue"),
        meta:{
            action:"read",
            resource:"Auth"
        }
    },


    {
        path: "/payment/chasing/list",
        name: "chasing-list",
        component: () => import("@/views/payment/chasing/chasings-list/chasingsList.vue"),
        meta:{
            action:"read",
            resource:"Auth"
        }
    },
]